// src/firebase.js

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZ1vuE1BMTF3hrNcBNjhMsWZByDVRkBvg",
  authDomain: "dnd-master-v2.firebaseapp.com",
  projectId: "dnd-master-v2",
  storageBucket: "dnd-master-v2.appspot.com",
  messagingSenderId: "842889822377",
  appId: "1:842889822377:web:eac59b76c293972ccfa9b8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
