import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore'; 
import { auth, db } from '../firebase'; 
import './BuyItems.css'; 

// Armor categories
const armorData = {
  lightArmor: [
    { name: 'Padded', cost: 5 },
    { name: 'Leather', cost: 10 },
    { name: 'Studded leather', cost: 45 }
  ],
  mediumArmor: [
    { name: 'Hide', cost: 10 },
    { name: 'Chain shirt', cost: 50 },
    { name: 'Scale mail', cost: 50 },
    { name: 'Breastplate', cost: 400 },
    { name: 'Half plate', cost: 750 }
  ],
  heavyArmor: [
    { name: 'Ring mail', cost: 30 },
    { name: 'Chain mail', cost: 75 },
    { name: 'Splint', cost: 200 },
    { name: 'Plate', cost: 1500 }
  ],
  shield: [
    { name: 'Shield', cost: 10 }
  ]
};

// Weapon categories
const weaponData = {
  simpleMeleeWeapons: [
    { name: 'Club', cost: 1 },
    { name: 'Dagger', cost: 2 },
    { name: 'Greatclub', cost: 2 },
    { name: 'Handaxe', cost: 5 },
    { name: 'Javelin', cost: 5 },
    { name: 'Light hammer', cost: 2 },
    { name: 'Mace', cost: 5 },
    { name: 'Quarterstaff', cost: 2 },
    { name: 'Sickle', cost: 1 },
    { name: 'Unarmed strike', cost: 0 },  // No cost for unarmed strike
    { name: 'Spear', cost: 1 }
  ],
  simpleRangedWeapons: [
    { name: 'Crossbow, light', cost: 25 },
    { name: 'Dart', cost: 5 },
    { name: 'Shortbow', cost: 25 },
    { name: 'Sling', cost: 1 }
  ],
  martialMeleeWeapons: [
    { name: 'Battleaxe', cost: 10 },
    { name: 'Flail', cost: 10 },
    { name: 'Glaive', cost: 20 },
    { name: 'Greataxe', cost: 30 },
    { name: 'Greatsword', cost: 50 },
    { name: 'Halberd', cost: 20 },
    { name: 'Lance', cost: 10 },
    { name: 'Longsword', cost: 15 },
    { name: 'Maul', cost: 10 },
    { name: 'Morningstar', cost: 15 },
    { name: 'Pike', cost: 5 },
    { name: 'Rapier', cost: 25 },
    { name: 'Scimitar', cost: 25 },
    { name: 'Shortsword', cost: 10 },
    { name: 'Trident', cost: 5 },
    { name: 'War pick', cost: 5 },
    { name: 'Warhammer', cost: 15 },
    { name: 'Whip', cost: 2 }
  ],
  martialRangedWeapons: [
    { name: 'Blowgun', cost: 10 },
    { name: 'Crossbow, hand', cost: 75 },
    { name: 'Crossbow, heavy', cost: 50 },
    { name: 'Longbow', cost: 50 }
  ]
};

// Equipment Packs
const equipmentPacks = [
    {
      name: "Burglar’s Pack",
      cost: 16,
      description: "Includes a backpack, a bag of 1,000 ball bearings, 10 feet of string, a bell, 5 candles, a crowbar, a hammer, 10 pitons, a hooded lantern, 2 flasks of oil, 5 days rations, a tinderbox, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
      items: ["Backpack", "Ball bearings (1,000)", "String (10 ft)", "Bell", "Candles (5)", "Crowbar", "Hammer", "Piton (10)", "Hooded lantern", "Flask of oil (2)", "Rations (5 days)", "Tinderbox", "Waterskin", "Hempen rope (50 ft)"]
    },
    {
      name: "Diplomat's Pack",
      cost: 39,
      description: "Includes a chest, 2 cases for maps and scrolls, a set of fine clothes, a bottle of ink, an ink pen, a lamp, 2 flasks of oil, 5 sheets of paper, a vial of perfume, sealing wax, and soap.",
      items: ["Chest", "Map case (2)", "Fine clothes", "Bottle of ink", "Ink pen", "Lamp", "Flask of oil (2)", "Paper (5 sheets)", "Perfume", "Sealing wax", "Soap"]
    },
    {
      name: "Dungeoneer’s Pack",
      cost: 12,
      description: "Includes a backpack, a crowbar, a hammer, 10 pitons, 10 torches, a tinderbox, 10 days of rations, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
      items: ["Backpack", "Crowbar", "Hammer", "Piton (10)", "Torch (10)", "Tinderbox", "Rations (10 days)", "Waterskin", "Hempen rope (50 ft)"]
    },
    {
      name: "Entertainer’s Pack",
      cost: 40,
      description: "Includes a backpack, a bedroll, 2 costumes, 5 candles, 5 days of rations, a waterskin, and a disguise kit.",
      items: ["Backpack", "Bedroll", "Costume (2)", "Candles (5)", "Rations (5 days)", "Waterskin", "Disguise kit"]
    },
    {
      name: "Explorer’s Pack",
      cost: 10,
      description: "Includes a backpack, a bedroll, a mess kit, a tinderbox, 10 torches, 10 days of rations, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
      items: ["Backpack", "Bedroll", "Mess kit", "Tinderbox", "Torch (10)", "Rations (10 days)", "Waterskin", "Hempen rope (50 ft)"]
    },
    {
      name: "Priest’s Pack",
      cost: 19,
      description: "Includes a backpack, a blanket, 10 candles, a tinderbox, an alms box, 2 blocks of incense, a censer, vestments, 2 days of rations, and a waterskin.",
      items: ["Backpack", "Blanket", "Candles (10)", "Tinderbox", "Alms box", "Incense (2 blocks)", "Censer", "Vestments", "Rations (2 days)", "Waterskin"]
    },
    {
      name: "Scholar’s Pack",
      cost: 40,
      description: "Includes a backpack, a book of lore, a bottle of ink, an ink pen, 10 sheets of parchment, a little bag of sand, and a small knife.",
      items: ["Backpack", "Book of lore", "Bottle of ink", "Ink pen", "Parchment (10 sheets)", "Bag of sand", "Small knife"]
    }
  ];
 

// Expandable table component for categories (armor, weapons)
function ExpandableTable({ title, categories, onBuy }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
  
    const handleBuy = () => {
      if (selectedItem) {
        const allItems = Object.values(categories).flat();
        const item = allItems.find(i => i.name === selectedItem);
        onBuy(item.name, item.cost);
        setSelectedItem('');
      }
    };
  
    return (
      <div className="expandable-table">
        <div className="expandable-header" onClick={() => setIsOpen(!isOpen)}>
          <h4>{title.charAt(0).toUpperCase() + title.slice(1)}</h4>
          <button className="expandable-toggle">-</button>
        </div>
        {isOpen && (
          <div className="expandable-content">
            <select value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
              <option value="">Select an item</option>
              {Object.entries(categories).map(([category, items]) => (
                <optgroup key={category} label={category.replace(/([A-Z])/g, ' $1').trim()}>
                  {items.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name} - {item.cost} gp
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            <button onClick={handleBuy} disabled={!selectedItem}>Buy</button>
          </div>
        )}
      </div>
    );
  }
  
  // Equipment Pack Table
  function EquipmentPackTable({ packs, onBuyPack }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPack, setSelectedPack] = useState(null);
  
    const handleBuyPack = () => {
      if (selectedPack) {
        onBuyPack(selectedPack.name, selectedPack.items, selectedPack.cost);
        setSelectedPack(null); 
      }
    };
  
    return (
      <div className="expandable-table">
        <div className="expandable-header" onClick={() => setIsOpen(!isOpen)}>
          <h4>Equipment Packs</h4>
          <button className="expandable-toggle">-</button>
        </div>
        {isOpen && (
          <div className="expandable-content pack-content">
            <select value={selectedPack ? selectedPack.name : ''} onChange={(e) => {
              const pack = packs.find(p => p.name === e.target.value);
              setSelectedPack(pack);
            }}>
              <option value="">Select a pack</option>
              {packs.map((pack, index) => (
                <option key={index} value={pack.name}>
                  {pack.name} - {pack.cost} gp
                </option>
              ))}
            </select>
            {selectedPack && (
              <div className="pack-description">
                <p>{selectedPack.description}</p>
                <button onClick={handleBuyPack}>Buy Pack</button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  
  function BuyItems() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { character } = state || {};
    const [remainingGold, setRemainingGold] = useState(character ? character.gold : 0);
    const [inventory, setInventory] = useState(character ? character.inventory : []);
    const [packNames, setPackNames] = useState([]);  // Pack names for UI
  
    useEffect(() => {
      if (!character) {
        navigate('/dashboard');
      }
    }, [character, navigate]);
  
    const addItem = (item, cost) => {
      if (remainingGold >= cost) {
        setInventory(prev => [...prev, item]);
        setRemainingGold(prevGold => prevGold - cost);
      } else {
        alert('Not enough gold');
      }
    };
  
    const addPackItems = (packName, items, cost) => {
      if (remainingGold >= cost) {
        setInventory(prev => [...prev, ...items]);
        setPackNames(prev => [...prev, packName]);  // Add pack name to display in UI
        setRemainingGold(prevGold => prevGold - cost);
      } else {
        alert('Not enough gold');
      }
    };
  
    const sellPack = (packName) => {
      const pack = equipmentPacks.find(p => p.name === packName);
      if (pack) {
        // Remove pack name from the UI
        setPackNames(prev => prev.filter(name => name !== packName));
        // Remove pack items from the inventory in the database
        setInventory(prevInventory => {
          return prevInventory.filter(item => !pack.items.includes(item));
        });
        setRemainingGold(prevGold => prevGold + pack.cost);
      }
    };
  
    const sellItem = (index) => {
      const item = inventory[index];
      const itemPrice = findItemPrice(item);
      setInventory(prev => prev.filter((_, i) => i !== index));
      setRemainingGold(prevGold => prevGold + Math.round(itemPrice / 2));
    };
  
    const findItemPrice = (item) => {
      const allItems = [...Object.values(armorData).flat(), ...Object.values(weaponData).flat(), ...equipmentPacks.flatMap(pack => pack.items)];
      const foundItem = allItems.find(i => i === item || i.name === item);
      return foundItem ? foundItem.cost || 0 : 0;
    };
  
    const createCharacter = async () => {
        try {
          const user = auth.currentUser;
          if (!user) {
            alert('User is not authenticated!');
            return;
          }
      
          // Ensure hitPoints are correctly passed along with the other character data
          const newCharacter = {
            name: character.name,
            level: character.level,
            race: character.race,
            alignment: character.alignment,
            gender: character.gender,
            class: character.class,
            stats: character.stats,
            hitPoints: character.hitPoints || 0,  // Default to 0 if undefined
            hitDie: character.hitDie || '1d8',  // Ensure hitDie is correctly set
            exp: character.exp,
            gold: remainingGold,
            inventory: inventory,
            userId: user.uid,
          };
      
          await addDoc(collection(db, 'characters'), newCharacter);
      
          alert('Character created successfully!');
          navigate('/dashboard');
        } catch (error) {
          console.error('Error creating character:', error);
          alert('There was an issue creating the character. Please try again.');
        }
      };
      
  
    return (
      <div className="buy-items-page">
        <h2>Buy Items for {character.name}</h2>
        <h3>Remaining Gold: {remainingGold} gp</h3>
  
        <div className="inventory-section">
          <h3>Current Inventory:</h3>
          <div className="inventory-grid">
            {inventory.length > 0 ? (
              inventory.map((item, index) => (
                <div key={index} className="inventory-item">
                  {item}
                  <button className="sell-back" onClick={() => sellItem(index)}>X</button>
                </div>
              ))
            ) : (
              <p>No items in inventory</p>
            )}
          </div>
  
          <h3>Purchased Packs:</h3>
          <div className="pack-grid">
            {packNames.length > 0 ? (
              packNames.map((packName, index) => (
                <div key={index} className="pack-item">
                  <strong>{packName}</strong>
                  <button className="sell-back" onClick={() => sellPack(packName)}>X</button>
                </div>
              ))
            ) : (
              <p>No packs purchased</p>
            )}
          </div>
        </div>
  
        <div className="buy-tables">
          <ExpandableTable title="Armor" categories={armorData} onBuy={addItem} />
          <ExpandableTable title="Weapons" categories={weaponData} onBuy={addItem} />
          <EquipmentPackTable packs={equipmentPacks} onBuyPack={addPackItems} />
        </div>
  
        <button onClick={() => navigate('/create-character')}>Back to Character Creation</button>
        <button onClick={createCharacter}>Create Character</button>
      </div>
    );
  }
  
  export default BuyItems;