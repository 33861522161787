import React, { useState } from 'react';
import './DiceRoller.css';

const DiceRoller = () => {
  const [diceConfigs, setDiceConfigs] = useState([
    { diceType: 'd6', diceCount: 1 },
  ]);
  const [results, setResults] = useState([]);
  const [rolling, setRolling] = useState(false);

  const diceSides = {
    d4: 4,
    d6: 6,
    d8: 8,
    d10: 10,
    d12: 12,
    d20: 20,
    d100: 100,
  };

  const rollDice = () => {
    setRolling(true);
    const allResults = diceConfigs.map(({ diceType, diceCount }) => {
      const sides = diceSides[diceType];
      return {
        diceType,
        rolls: Array.from({ length: diceCount }, () =>
          Math.floor(Math.random() * sides) + 1
        ),
      };
    });
    setTimeout(() => {
      setResults(allResults);
      setRolling(false);
    }, 1500); // Simulate dice roll animation duration
  };

  const addDiceRow = () => {
    if (diceConfigs.length < 7) {
      setDiceConfigs([...diceConfigs, { diceType: 'd6', diceCount: 1 }]);
    }
  };

  const removeDiceRow = (index) => {
    setDiceConfigs(diceConfigs.filter((_, i) => i !== index));
  };

  const updateDiceConfig = (index, key, value) => {
    const updatedConfigs = diceConfigs.map((config, i) =>
      i === index ? { ...config, [key]: value } : config
    );
    setDiceConfigs(updatedConfigs);
  };

  return (
    <div className="dice-roller">
      <h1>Dice Roller</h1>
      <div className="controls">
        {diceConfigs.map((config, index) => (
          <div key={index} className="dice-row">
            <label>
              Dice Type:
              <select
                value={config.diceType}
                onChange={(e) =>
                  updateDiceConfig(index, 'diceType', e.target.value)
                }
              >
                {Object.keys(diceSides).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Count:
              <select
                value={config.diceCount}
                onChange={(e) =>
                  updateDiceConfig(index, 'diceCount', Number(e.target.value))
                }
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((count) => (
                  <option key={count} value={count}>
                    {count}
                  </option>
                ))}
              </select>
            </label>
            {index > 0 && (
              <button
                className="remove-button"
                onClick={() => removeDiceRow(index)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        {diceConfigs.length < 7 && (
          <button className="add-button" onClick={addDiceRow}>
            + Add Dice
          </button>
        )}
      </div>
      <button
        className="dice-buttons button"
        onClick={rollDice}
        disabled={rolling}
      >
        {rolling ? 'Rolling...' : 'Roll Dice'}
      </button>
      <div className="results">
        <h2>Results</h2>
        {results.length > 0 &&
          results.map(({ diceType, rolls }, index) => (
            <div key={index}>
              <p>
                <strong>{diceType}:</strong> {rolls.join(', ')}
              </p>
              <p>Total: {rolls.reduce((a, b) => a + b, 0)}</p>
            </div>
          ))}
      </div>
      {rolling && <div className="dice-animation">🎲 Rolling...</div>}
    </div>
  );
};

export default DiceRoller;
