// Import Firestore functions and Firebase authentication
import { addDoc, collection, doc, getDoc, updateDoc, increment, arrayUnion, arrayRemove } from 'firebase/firestore'; 
import { auth, db } from '../firebase'; // Assuming db and auth are initialized in your firebase.js file

// Save character progress including EXP, inventory, gold, and story with chat history
export const saveCharacterProgress = async (characterId, expGained, itemGained, itemTaken, responseContent, chatHistory) => {
  try {
    const characterRef = doc(db, 'characters', characterId);

    // Fetch the existing character data to prevent overwriting fields
    const characterSnap = await getDoc(characterRef);
    if (!characterSnap.exists()) {
      throw new Error('Character does not exist');
    }

    const currentCharacter = characterSnap.data();  // Existing character data

    // Update only the necessary fields while keeping the rest intact
    const updateData = {
      exp: increment(expGained),  // Increment experience points
      currentStory: responseContent || currentCharacter.currentStory || '',  // Merge current story
      inventory: currentCharacter.inventory || [],  // Merge inventory
      gold: currentCharacter.gold !== undefined ? currentCharacter.gold : 0,  // Merge gold
      hitDie: currentCharacter.hitDie || '1d8',  // Merge hitDie
      hitPoints: currentCharacter.hitPoints !== undefined ? currentCharacter.hitPoints : 0,  // Merge hitPoints
      chatHistory: chatHistory || currentCharacter.chatHistory || []  // Store chat history
    };

    // Add gained item if available
    if (itemGained) {
      updateData.inventory = arrayUnion(itemGained);
    }

    // Remove taken item if available
    if (itemTaken) {
      updateData.inventory = arrayRemove(itemTaken);
    }

    await updateDoc(characterRef, updateData);

    console.log('Character progress and chat history saved successfully!');
  } catch (error) {
    console.error('Error saving character progress:', error);
  }
};
