import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import "./Login.css";
import googleIcon from "../img/googleicon.png";
import AdSenseAd from "../components/AdSenseAd";

function Login() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/dashboard");
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      alert("Failed to sign in with Google");
    }
  };

  return (
    <div className="login-container">
      <h2 className="typing-text">Welcome to TheQuest.ai!</h2>
      <p>Create a character and embark on your custom adventure.</p>
      <button className="google-button" onClick={handleGoogleSignIn}>
        <img src={googleIcon} alt="Google icon" className="google-icon" />
        Sign in with Google
      </button>
      <AdSenseAd />
     
    </div>
  );
}

export default Login;
