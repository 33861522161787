// src/components/AdSenseAd.js
import React, { useEffect } from "react";

const AdSenseAd = () => {
  useEffect(() => {
    if (window) {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7044364549716226";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);

      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block", width: "100%", height: "90px" }}
        data-ad-client="ca-pub-7044364549716226"
        data-ad-slot="3438713445"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      
    </div>
  );
};

export default AdSenseAd;
