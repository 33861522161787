import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { saveCharacterProgress } from "../services/characterService";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./PlayGame.css";

function PlayGame() {
  const { state } = useLocation();
  const { character } = state || {};
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!character) navigate("/dashboard");
  }, [character, navigate]);

  // Load chat history on component mount
  useEffect(() => {
    const loadChatHistory = async () => {
      try {
        const characterRef = doc(db, "characters", character.id);
        const characterDoc = await getDoc(characterRef);
        if (characterDoc.exists()) {
          const characterData = characterDoc.data();
          setChatHistory(characterData.chatHistory || []);
        }
      } catch (error) {
        console.error("Error loading chat history:", error);
      }
    };

    loadChatHistory();
  }, [character.id]);

  const startGame = async () => {
    if (chatHistory.length > 0) return; // Prevents starting a new game if chat history exists

    const assistantRulesMessage = {
      role: "assistant",
      content: `
        You are the most senior experienced Dungeon Master (DM) for a fantasy role-playing game in the world of Dungeons and Dragons.You have a persona you must take on Name: ${character.dungeonMaster.name}, Your persona is: ${character.dungeonMaster.description}.
          You must retain and reference the following character data given by the user. Repeat the players information back at the start of the  game and then follow the rest of these rules.
          Follow these rules and Format responses with sections
          Keep your responses to 600 Characters
          **Story**: Main narrative
          **Action**: Description of events, battles, or puzzles.
          **Item Gained:** + "item(s)"
          **Item Taken:** + "item(s) removed".
          **Puzzle Solved** or **Monster Defeated**
          **Choices**: Numbered options for the player.
          You will run the game as a choose your own text adventure style game, where you will provide story/context and give the player 3 options to choose from. However they are able to be creative and come up with their own option and you will adjust the story to their ideas.
          You must also retain and reference their character data while keeping track their Gold, Items, Experience points and health
          As a master story teller you are capable of including puzzles and challenging battles for the player.
          For any battle or encounters you will always let the player decide their next action or attack and you will NOT play out the whole battle automatically.
          You will use a dice roll system for attacks and skill successes and failures
          You will start the game when the user says "Start Game"
      `,
    };

    const characterDataMessage = {
      role: "user",
      content: `Start Game. The player's character data is as follows:
          Name: ${character.name},
          Gender: ${character.gender}, 
          Race: ${character.race}, 
          Class: ${character.class}, 
          Level: ${character.level}, 
          Alignment: ${character.alignment}, 
          Background: ${character.background},
          Stats: ${JSON.stringify(character.stats)}, 
          Health: ${character.hitPoints || 0},
          HitDie ${character.hitDie || 0} ,
          EXP: ${character.exp || 0}, 
          Gold: ${character.gold || 0}, 
          Proficient Skills: ${character.proficientSkills || "None"}
          Inventory: ${
            character.inventory && character.inventory.length > 0
              ? character.inventory.map((item) => item.name).join(", ")
              : "Nothing"
          }, You are ${character.dungeonMaster.name}, Your persona to act like is: ${
        character.dungeonMaster.description
      }.
          Start the adventure in a fantasy setting..`,
    };

    const response = await sendMessageToChatGPT([
      assistantRulesMessage,
      characterDataMessage,
    ]);
    const initialHistory = [response];
    setChatHistory(initialHistory);

    saveCharacterProgress(
      character.id,
      0,
      null,
      null,
      response.content,
      initialHistory
    );
  };

  const sendMessageToChatGPT = async (messages) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini-2024-07-18",
          messages,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );
      return response.data.choices[0].message;
    } catch (error) {
      console.error("Error communicating with ChatGPT:", error);
      return {
        role: "assistant",
        content: "Something went wrong. Please try again later.",
      };
    }
  };

  const handleSendMessage = async () => {
    const playerMessage = { role: "user", content: userInput };
    const newHistory = [...chatHistory, playerMessage];

    const response = await sendMessageToChatGPT(newHistory);
    const updatedHistory = [...newHistory, response];
    setChatHistory(updatedHistory);
    setUserInput("");

    saveCharacterProgress(
      character.id,
      0,
      null,
      null,
      response.content,
      updatedHistory
    );
  };

  return (
    <div className="play-game-container">
      <h1>{character.dungeonMaster.name} Adventure with {character.name}</h1>
  
  
      <div className="chat-box">
        {chatHistory.map((msg, index) => (
          <div key={index} className={msg.role === "user" ? "user-message" : "dm-message"}>
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          </div>
        ))}
      </div>
  
      {chatHistory.length === 0 && (
        <button className="back-button" onClick={startGame}>Start Game</button>
      )}
  
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        placeholder="Type your response here..."
      />
       {/* Portraits and Buttons Container */}
       <div className="portraits-and-actions">
        {/* Dungeon Master Portrait */}
        <div className="portrait-wrapper">
          <img
            src={character.dungeonMaster.image}
            alt={`${character.dungeonMaster.name} portrait`}
            className="dungeon-master-portrait"
          />
        </div>
  
        {/* Action Buttons */}
        <div className="action-buttons">
          <button className="action-button" onClick={handleSendMessage}>
            Send
          </button>
          <button className="action-button" onClick={() => navigate("/dashboard")}>
            Back to Dashboard
          </button>
        </div>
  
        {/* Player Character Portrait */}
        <div className="portrait-wrapper">
          <img
            src={character.portrait}
            alt={`${character.name} portrait`}
            className="player-character-portrait"
          />
        </div>
      </div>
    </div>
    
    
  );
}  

export default PlayGame;
