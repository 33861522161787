import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import AdSenseAd from '../components/AdSenseAd';

function Dashboard() {
  const [characters, setCharacters] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCharacters = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate("/");
        return;
      }

      const q = query(
        collection(db, "characters"),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      const chars = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCharacters(chars);
    };

    fetchCharacters();
  }, [navigate]);

  const handleSelectCharacter = (character) => {
    navigate("/play-game", { state: { character } });
  };

  const handleDeleteCharacter = async (characterId) => {
    try {
      await deleteDoc(doc(db, "characters", characterId));
      setCharacters(characters.filter((char) => char.id !== characterId));
    } catch (error) {
      console.error("Error deleting character:", error);
    }
  };

  const showModal = (char) => {
    setModalContent(char);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div className="dashboard-container">
      <h1>Your Characters</h1>
      <ul className="character-list">
        {[...Array(6)].map((_, index) => {
          const char = characters[index];
          return char ? (
            <li key={char.id} className="character-card">
              <h2>{char.name}</h2>
              {char.portrait && (
                <img
                  src={char.portrait}
                  alt={`${char.name}'s portrait`}
                  className="portrait-thumbnail"
                  onError={(e) => {
                    console.error("Error loading portrait for", char.name, e);
                    e.target.src = "/img/default-portrait.png"; // Use a fallback image if needed
                  }} />
              )}
              <div className="button-row">
                <img
                  src="/img/info128.png"
                  alt="Show Details"
                  className="icon-button"
                  onClick={() => showModal(char)}
                />
                <img
                  src="/img/play128.png"
                  alt="Play"
                  className="icon-button"
                  onClick={() => handleSelectCharacter(char)}
                />
                <img
                  src="/img/delete128.png"
                  alt="Delete"
                  className="icon-button"
                  onClick={() => handleDeleteCharacter(char.id)}
                />
              </div>
            </li>
          ) : (
            <li
              key={index}
              className="character-card placeholder-card"
              onClick={() => navigate("/test-page")}
            >
              <h2>Create Character</h2>
            </li>
          );
        })}
      </ul>

      {modalContent && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{modalContent.name}</h2>
            <p>Class: {modalContent.class}</p>
            <p>Race: {modalContent.race}</p>
            <p>Level: {modalContent.level}</p>
            <p>Alignment: {modalContent.alignment}</p>
            <p>Gender: {modalContent.gender}</p>
            <p>Hitpoints: {modalContent.hitPoints || 0}</p>
            <p>Experience: {modalContent.experience}</p>
            <h3>Stats:</h3>
            <p>Strength: {modalContent.stats.str}</p>
            <p>Dexterity: {modalContent.stats.dex}</p>
            <p>Constitution: {modalContent.stats.con}</p>
            <p>Intelligence: {modalContent.stats.int}</p>
            <p>Wisdom: {modalContent.stats.wis}</p>
            <p>Charisma: {modalContent.stats.cha}</p>
            <h3>Inventory:</h3>
            <ul>
              {modalContent.inventory && modalContent.inventory.length > 0 ? (
                modalContent.inventory.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))
              ) : (
                <li>No items in inventory</li>
              )}
            </ul>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
