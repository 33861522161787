// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import CharacterCreation from './pages/CharacterCreation';
import PlayGame from './pages/PlayGame';
import BuyItems from './pages/BuyItems';  // Import BuyItems page
import TestPage from './pages/TestPage';
import DiceRoller from './pages/DiceRoller';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-character" element={<CharacterCreation />} />
        <Route path="/play-game" element={<PlayGame />} />
        <Route path="/buy-items" element={<BuyItems />} />  {/* Add route for BuyItems */}
        <Route path="/test-page" element={<TestPage />} />
        <Route path="/dice-roller" element={<DiceRoller />}/>
      </Routes>
    </Router>
  );
}

export default App;
