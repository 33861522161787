import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CharacterCreation.css';

const races = ['Dragonborn', 'Dwarf', 'Elf', 'Gnome', 'Half-Elf', 'Halfling', 'Half-Orc', 'Human', 'Tiefling'];
const alignments = [
  'Lawful Good', 'Lawful Neutral', 'Lawful Evil',
  'True Neutral', 'Neutral Good', 'Neutral Evil',
  'Chaotic Good', 'Chaotic Neutral', 'Chaotic Evil',
];
const genders = ['Male', 'Female'];
const classesMale = ['Barbarian', 'Rogue', 'Druid', 'Bard', 'Fighter', 'Monk', 'Paladin', 'Ranger', 'Warlock', 'Wizard'];
const classesFemale = ['Barbarian', 'Rogue', 'Druid', 'Bard', 'Fighter', 'Monk', 'Paladin', 'Ranger', 'Warlock', 'Sorcerer'];

// Racial stat modifiers and descriptions
const racialModifiers = {
  'Dragonborn': { str: 2, cha: 1, description: '+2 Str, +1 Cha' },
  'Dwarf': { con: 2, description: '+2 Con' },
  'Elf': { dex: 2, description: '+2 Dex' },
  'Gnome': { int: 2, description: '+2 Int' },
  'Half-Elf': { cha: 2, description: '+2 Cha' },
  'Halfling': { dex: 2, description: '+2 Dex' },
  'Half-Orc': { str: 2, con: 1, description: '+2 Str, +1 Con' },
  'Human': { str: 1, dex: 1, con: 1, int: 1, wis: 1, cha: 1, description: '+1 to all stats' },
  'Tiefling': { cha: 2, int: 1, description: '+2 Cha, +1 Int' },
};

// Hit dice for classes
const hitDie = {
  Barbarian: 12,
  Bard: 8,
  Cleric: 8,
  Druid: 8,
  Monk: 8,
  Rogue: 8,
  Warlock: 8,
  Fighter: 10,
  Paladin: 10,
  Ranger: 10,
  Sorcerer: 6,
  Wizard: 6,
};

// Class Funds for rolling wealth
const classFunds = {
  Barbarian: { rolls: 2, die: 4, multiplier: 10 },
  Bard: { rolls: 5, die: 4, multiplier: 10 },
  Cleric: { rolls: 5, die: 4, multiplier: 10 },
  Druid: { rolls: 2, die: 4, multiplier: 10 },
  Fighter: { rolls: 5, die: 4, multiplier: 10 },
  Monk: { rolls: 5, die: 4, multiplier: 1 },
  Paladin: { rolls: 5, die: 4, multiplier: 10 },
  Ranger: { rolls: 5, die: 4, multiplier: 10 },
  Rogue: { rolls: 4, die: 4, multiplier: 10 },
  Sorcerer: { rolls: 3, die: 4, multiplier: 10 },
  Warlock: { rolls: 4, die: 4, multiplier: 10 },
  Wizard: { rolls: 4, die: 4, multiplier: 10 },
};

function CharacterCreation() {
  const navigate = useNavigate();
  const [character, setCharacter] = useState({
    name: '',
    level: 1,
    race: races[0],
    alignment: alignments[0],
    gender: genders[0],
    class: classesMale[0],
    stats: { str: 0, dex: 0, con: 0, int: 0, wis: 0, cha: 0 },
    hitPoints: 0,
    exp: 0,
    gold: 0,
    inventory: [],
    hitDie: 0, // Add hitDie to the character object
    characterStory: '',
  });

  const [statSets, setStatSets] = useState([]);
  const [selectedStatSet, setSelectedStatSet] = useState(null);
  const [rollEnabled, setRollEnabled] = useState(true);
  const [wealthRolled, setWealthRolled] = useState(false);

  const rollDice = (rolls, die) => {
    return Array.from({ length: rolls }).reduce((sum) => sum + Math.floor(Math.random() * die) + 1, 0);
  };

  const rollStat = () => {
    const rolls = Array.from({ length: 4 }, () => Math.floor(Math.random() * 6) + 1);
    return rolls.sort().slice(1).reduce((a, b) => a + b, 0);  // Drop lowest roll
  };

  const applyRacialModifiers = (stats, race) => {
    const modifiers = racialModifiers[race] || {};
    const modifiedStats = { ...stats };
    for (const [stat, bonus] of Object.entries(modifiers)) {
      modifiedStats[stat] += bonus;
    }
    return modifiedStats;
  };

  const rollStats = () => {
    if (!rollEnabled) return;
    const sets = [];
    for (let i = 0; i < 3; i++) {
      const baseStats = {
        str: rollStat(),
        dex: rollStat(),
        con: rollStat(),
        int: rollStat(),
        wis: rollStat(),
        cha: rollStat(),
      };
      sets.push(applyRacialModifiers(baseStats, character.race));
    }
    setStatSets(sets);
    setSelectedStatSet(null);
    setRollEnabled(false);
  };

  const handleClassChange = (e) => {
    const newClass = e.target.value;
    const hitDieValue = hitDie[newClass]; // Get hitDie based on class

    setCharacter((prev) => ({
      ...prev,
      class: newClass,
      hitPoints: 0,
      hitDie: hitDieValue, // Set hitDie value
    }));
    setStatSets([]);
    setRollEnabled(true);
    setWealthRolled(false);
    setSelectedStatSet(null);
  };

  const handleGenderChange = (e) => {
    const newGender = e.target.value;
    setCharacter((prev) => ({
      ...prev,
      gender: newGender,
      class: newGender === 'Male' ? classesMale[0] : classesFemale[0],
      hitDie: hitDie[newGender === 'Male' ? classesMale[0] : classesFemale[0]], // Set hitDie based on gender
    }));
    setRollEnabled(true);
    setWealthRolled(false);
    setStatSets([]);
  };

  const rollWealth = () => {
    if (!wealthRolled) {
      const { rolls, die, multiplier } = classFunds[character.class] || {};
      const rolledWealth = rollDice(rolls, die) * multiplier;
      setCharacter((prev) => ({ ...prev, gold: rolledWealth }));
      setWealthRolled(true);
    }
  };

  const calculateHitPoints = (selectedSet) => {
    const baseHP = hitDie[character.class] || 8;  // Default to 8 if hitDie is missing
    const conBonus = selectedSet ? Math.floor((selectedSet.con - 10) / 2) : 0;  // Constitution modifier
    return baseHP + conBonus;  // Return total hitPoints
  };
  
  // When a stat set is selected, hitPoints are calculated and saved
  const handleSelectStatSet = (index) => {
    const selectedSet = statSets[index];
    const calculatedHitPoints = calculateHitPoints(selectedSet);
    setCharacter((prev) => ({
      ...prev,
      stats: selectedSet,
      hitPoints: calculatedHitPoints,  // Save hitPoints
    }));
    setSelectedStatSet(selectedSet);
  };
  

  return (
    <div>
      <h1>Create Your Character</h1>
      <input
        type="text"
        name="name"
        placeholder="Character Name"
        onChange={(e) => setCharacter((prev) => ({ ...prev, name: e.target.value }))}
        value={character.name}
      />

      {/* Level is restricted to 1 */}
      <select name="level" value={character.level} disabled>
        <option value="1">Level 1</option>
      </select>

      <div className="row">
        <select name="race" onChange={(e) => setCharacter((prev) => ({ ...prev, race: e.target.value }))} value={character.race}>
          {races.map((race) => (
            <option key={race} value={race}>{race}</option>
          ))}
        </select>

        <select name="alignment" onChange={(e) => setCharacter((prev) => ({ ...prev, alignment: e.target.value }))} value={character.alignment}>
          {alignments.map((alignment) => (
            <option key={alignment} value={alignment}>{alignment}</option>
          ))}
        </select>

        <select name="gender" onChange={handleGenderChange} value={character.gender}>
          {genders.map((gender) => (
            <option key={gender} value={gender}>{gender}</option>
          ))}
        </select>

        {/* Conditional class selection based on gender */}
        <select name="class" onChange={handleClassChange} value={character.class}>
          {(character.gender === 'Male' ? classesMale : classesFemale).map((cls) => (
            <option key={cls} value={cls}>{cls}</option>
          ))}
        </select>
      </div>

      <p><strong>Racial Traits:</strong> {racialModifiers[character.race].description}</p>

      <button onClick={rollStats} disabled={!rollEnabled}>Roll Stats</button>

      {statSets.length > 0 && (
        <div className="stat-options">
          <h3>Select Starting Stats:</h3>
          <div className="stat-set-columns">
            {statSets.map((set, index) => (
              <div key={index} className="stat-set">
                <h4>Option {index + 1}</h4>
                <label>
                  Str: <span style={{ color: racialModifiers[character.race].str ? 'green' : 'black' }}>{set.str}</span><br/>
                  Dex: <span style={{ color: racialModifiers[character.race].dex ? 'green' : 'black' }}>{set.dex}</span><br/>
                  Con: <span style={{ color: racialModifiers[character.race].con ? 'green' : 'black' }}>{set.con}</span><br/>
                  Int: <span style={{ color: racialModifiers[character.race].int ? 'green' : 'black' }}>{set.int}</span><br/>
                  Wis: {set.wis}<br/>
                  Cha: <span style={{ color: racialModifiers[character.race].cha ? 'green' : 'black' }}>{set.cha}</span>
                </label>
                <input type="radio" name="statSet" value={index} onChange={() => handleSelectStatSet(index)} />
              </div>
            ))}
          </div>
        </div>
      )}

      <h4>Starting Experience: {character.exp}</h4>
      <h4>Hit Die: {hitDie[character.class]}</h4>
      <h4>Hit Points: {selectedStatSet ? calculateHitPoints() : 'N/A'}</h4>
      <h4>Gold: {character.gold}</h4>
      <button onClick={rollWealth} disabled={wealthRolled}>Roll Wealth</button>

      {/* Navigate to Buy Items page */}
      {wealthRolled && (
        <button onClick={() => navigate(`/buy-items`, { state: { character } })}>Buy Items</button>
      )}
    </div>
  );
}

export default CharacterCreation;
