import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storage, db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./TestPage.css";
import AdSenseAd from "../components/AdSenseAd";

const classSkillOptions = {
  Barbarian: [
    "Animal Handling",
    "Athletics",
    "Intimidation",
    "Nature",
    "Perception",
    "Survival",
  ],
  Bard: [
    "Acrobatics",
    "Animal Handling",
    "Arcana",
    "Athletics",
    "Deception",
    "History",
    "Insight",
    "Intimidation",
    "Investigation",
    "Medicine",
    "Nature",
    "Perception",
    "Performance",
    "Persuasion",
    "Religion",
    "Sleight of Hand",
    "Stealth",
    "Survival",
  ],
  Cleric: ["History", "Insight", "Medicine", "Persuasion", "Religion"],
  Druid: [
    "Arcana",
    "Animal Handling",
    "Insight",
    "Medicine",
    "Nature",
    "Perception",
    "Religion",
    "Survival",
  ],
  Fighter: [
    "Acrobatics",
    "Animal Handling",
    "Athletics",
    "History",
    "Insight",
    "Intimidation",
    "Perception",
    "Survival",
  ],
  Monk: [
    "Acrobatics",
    "Athletics",
    "History",
    "Insight",
    "Religion",
    "Stealth",
  ],
  Paladin: [
    "Athletics",
    "Insight",
    "Intimidation",
    "Medicine",
    "Persuasion",
    "Religion",
  ],
  Ranger: [
    "Animal Handling",
    "Athletics",
    "Insight",
    "Investigation",
    "Nature",
    "Perception",
    "Stealth",
    "Survival",
  ],
  Rogue: [
    "Acrobatics",
    "Athletics",
    "Deception",
    "Insight",
    "Intimidation",
    "Investigation",
    "Perception",
    "Performance",
    "Persuasion",
    "Sleight of Hand",
    "Stealth",
  ],
  Sorcerer: [
    "Arcana",
    "Deception",
    "Insight",
    "Intimidation",
    "Persuasion",
    "Religion",
  ],
  Warlock: [
    "Arcana",
    "Deception",
    "History",
    "Intimidation",
    "Investigation",
    "Nature",
    "Religion",
  ],
  Wizard: [
    "Arcana",
    "History",
    "Insight",
    "Investigation",
    "Medicine",
    "Religion",
  ],
};
const skillsPerClass = {
  Barbarian: 2,
  Bard: 3,
  Cleric: 2,
  Druid: 2,
  Fighter: 2,
  Monk: 2,
  Paladin: 2,
  Ranger: 3,
  Rogue: 4,
  Sorcerer: 2,
  Warlock: 2,
  Wizard: 2,
};
const tabs = ["Background", "Items / Misc", "Dungeon Master"];
const races = [
  "Dragonborn",
  "Dwarf",
  "Elf",
  "Gnome",
  "Half-Elf",
  "Halfling",
  "Half-Orc",
  "Human",
  "Tiefling",
];
const alignments = [
  "Lawful Good",
  "Lawful Neutral",
  "Lawful Evil",
  "True Neutral",
  "Neutral Good",
  "Neutral Evil",
  "Chaotic Good",
  "Chaotic Neutral",
  "Chaotic Evil",
];
const genders = ["Male", "Female"];
const classesMale = [
  "Barbarian",
  "Rogue",
  "Druid",
  "Bard",
  "Fighter",
  "Monk",
  "Paladin",
  "Ranger",
  "Warlock",
  "Wizard",
];
const classesFemale = [
  "Barbarian",
  "Rogue",
  "Druid",
  "Bard",
  "Fighter",
  "Monk",
  "Paladin",
  "Ranger",
  "Warlock",
  "Sorcerer",
];

const backgrounds = {
  Acolyte: {
    description: "Shelter of the Faithful",
    skills: ["Insight", "Religion"],
    equipment: ["Holy Symbol", "Prayer Book"],
    gold: 15,
  },
  Charlatan: {
    description: "Scam Artist",
    skills: ["Deception", "Sleight of Hand"],
    equipment: ["Disguise Kit", "Weighted Dice"],
    gold: 15,
  },
  Criminal: {
    description: "Criminal Contact",
    skills: ["Deception", "Stealth"],
    equipment: ["Crowbar", "Dark Clothes"],
    gold: 15,
  },
  Entertainer: {
    description: "By Popular Demand",
    skills: ["Acrobatics", "Performance"],
    equipment: ["Musical Instrument", "Costume"],
    gold: 15,
  },
  "Folk Hero": {
    description: "Rustic Hospitality",
    skills: ["Animal Handling", "Survival"],
    equipment: ["Shovel", "Iron Pot"],
    gold: 10,
  },
  "Guild Artisan": {
    description: "Guild Membership",
    skills: ["Insight", "Persuasion"],
    equipment: ["Letter of Introduction", "Fine Clothes"],
    gold: 15,
  },
  Hermit: {
    description: "Discovery",
    skills: ["Medicine", "Religion"],
    equipment: ["Scroll of Pedigree", "Herbalism Kit"],
    gold: 5,
  },
  Noble: {
    description: "Position of Privilege",
    skills: ["History", "Persuasion"],
    equipment: ["Fine Clothes", "Signet Ring"],
    gold: 25,
  },
  Outlander: {
    description: "Wanderer",
    skills: ["Athletics", "Survival"],
    equipment: ["Staff", "Hunting Trap"],
    gold: 10,
  },
  Sage: {
    description: "Researcher",
    skills: ["Arcana", "History"],
    equipment: ["Quill and Ink", "Common Clothes"],
    gold: 10,
  },
  Sailor: {
    description: "Ship's Passage",
    skills: ["Athletics", "Perception"],
    equipment: ["Sailor's Clothes", "Lucky Charm"],
    gold: 10,
  },
  Soldier: {
    description: "Military Rank",
    skills: ["Athletics", "Intimidation"],
    equipment: ["Insignia of Rank", "Trophy from a Battle"],
    gold: 10,
  },
  Urchin: {
    description: "City Secrets",
    skills: ["Sleight of Hand", "Stealth"],
    equipment: ["Small Knife", "Map of the City"],
    gold: 10,
  },
};

const racialModifiers = {
  Dragonborn: { str: 2, cha: 1, description: "+2 Str, +1 Cha" },
  Dwarf: { con: 2, description: "+2 Con" },
  Elf: { dex: 2, description: "+2 Dex" },
  Gnome: { int: 2, description: "+2 Int" },
  "Half-Elf": { cha: 2, description: "+2 Cha" },
  Halfling: { dex: 2, description: "+2 Dex" },
  "Half-Orc": { str: 2, con: 1, description: "+2 Str, +1 Con" },
  Human: {
    str: 1,
    dex: 1,
    con: 1,
    int: 1,
    wis: 1,
    cha: 1,
    description: "+1 to all stats",
  },
  Tiefling: { cha: 2, int: 1, description: "+2 Cha, +1 Int" },
};

const hitDieByClass = {
  Barbarian: 12,
  Bard: 8,
  Cleric: 8,
  Druid: 8,
  Fighter: 10,
  Monk: 8,
  Paladin: 10,
  Ranger: 10,
  Rogue: 8,
  Sorcerer: 6,
  Warlock: 8,
  Wizard: 6,
};

const startingGoldByClass = {
  Barbarian: "2d4 * 10",
  Bard: "5d4 * 10",
  Cleric: "5d4 * 10",
  Druid: "2d4 * 10",
  Fighter: "5d4 * 10",
  Monk: "5d4",
  Paladin: "5d4 * 10",
  Ranger: "5d4 * 10",
  Rogue: "4d4 * 10",
  Sorcerer: "3d4 * 10",
  Warlock: "4d4 * 10",
  Wizard: "4d4 * 10",
};

const defaultStats = { str: 8, dex: 8, con: 8, int: 8, wis: 8, cha: 8 };

const armorData = {
  lightArmor: [
    { name: "Padded Leather", cost: 5 },
    { name: "Leather Armor", cost: 10 },
    { name: "Studded Leather", cost: 45 },
  ],
  mediumArmor: [
    { name: "Hide Armor", cost: 10 },
    { name: "Chain shirt", cost: 50 },
    { name: "Scale mail", cost: 50 },
    { name: "Breastplate", cost: 400 },
    { name: "Half plate", cost: 750 },
  ],
  heavyArmor: [
    { name: "Ring mail", cost: 30 },
    { name: "Chain mail", cost: 75 },
    { name: "Splint mail", cost: 200 },
    { name: "Plate mail", cost: 1500 },
  ],
  shield: [{ name: "Shield", cost: 10 }],
};

const weaponData = {
  simpleMeleeWeapons: [
    { name: "Club", cost: 1 },
    { name: "Dagger", cost: 2 },
    { name: "Greatclub", cost: 2 },
    { name: "Handaxe", cost: 5 },
    { name: "Javelin", cost: 5 },
    { name: "Light hammer", cost: 2 },
    { name: "Mace", cost: 5 },
    { name: "Quarterstaff", cost: 2 },
    { name: "Sickle", cost: 1 },
    { name: "Spear", cost: 1 },
  ],
  simpleRangedWeapons: [
    { name: "Crossbow, light", cost: 25 },
    { name: "Dart", cost: 5 },
    { name: "Shortbow", cost: 25 },
    { name: "Sling", cost: 1 },
  ],
  martialMeleeWeapons: [
    { name: "Battleaxe", cost: 10 },
    { name: "Flail", cost: 10 },
    { name: "Glaive", cost: 20 },
    { name: "Greataxe", cost: 30 },
    { name: "Greatsword", cost: 50 },
    { name: "Longsword", cost: 15 },
  ],
};

const equipmentPacks = [
  {
    name: "Burglar’s Pack",
    cost: 16,
    description:
      "Includes a backpack, a bag of 1,000 ball bearings, 10 feet of string, a bell, 5 candles, a crowbar, a hammer, 10 pitons, a hooded lantern, 2 flasks of oil, 5 days rations, a tinderbox, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
    items: [
      "Backpack",
      "Ball bearings (1,000)",
      "String (10 ft)",
      "Bell",
      "Candles (5)",
      "Crowbar",
      "Hammer",
      "Piton (10)",
      "Hooded lantern",
      "Flask of oil (2)",
      "Rations (5 days)",
      "Tinderbox",
      "Waterskin",
      "Hempen rope (50 ft)",
    ],
  },
  {
    name: "Diplomat's Pack",
    cost: 39,
    description:
      "Includes a chest, 2 cases for maps and scrolls, a set of fine clothes, a bottle of ink, an ink pen, a lamp, 2 flasks of oil, 5 sheets of paper, a vial of perfume, sealing wax, and soap.",
    items: [
      "Chest",
      "Map case (2)",
      "Fine clothes",
      "Bottle of ink",
      "Ink pen",
      "Lamp",
      "Flask of oil (2)",
      "Paper (5 sheets)",
      "Perfume",
      "Sealing wax",
      "Soap",
    ],
  },
  {
    name: "Dungeoneer’s Pack",
    cost: 12,
    description:
      "Includes a backpack, a crowbar, a hammer, 10 pitons, 10 torches, a tinderbox, 10 days of rations, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
    items: [
      "Backpack",
      "Crowbar",
      "Hammer",
      "Piton (10)",
      "Torch (10)",
      "Tinderbox",
      "Rations (10 days)",
      "Waterskin",
      "Hempen rope (50 ft)",
    ],
  },
  {
    name: "Entertainer’s Pack",
    cost: 40,
    description:
      "Includes a backpack, a bedroll, 2 costumes, 5 candles, 5 days of rations, a waterskin, and a disguise kit.",
    items: [
      "Backpack",
      "Bedroll",
      "Costume (2)",
      "Candles (5)",
      "Rations (5 days)",
      "Waterskin",
      "Disguise kit",
    ],
  },
  {
    name: "Explorer’s Pack",
    cost: 10,
    description:
      "Includes a backpack, a bedroll, a mess kit, a tinderbox, 10 torches, 10 days of rations, and a waterskin. The pack also has 50 feet of hempen rope strapped to the side of it.",
    items: [
      "Backpack",
      "Bedroll",
      "Mess kit",
      "Tinderbox",
      "Torch (10)",
      "Rations (10 days)",
      "Waterskin",
      "Hempen rope (50 ft)",
    ],
  },
  {
    name: "Priest’s Pack",
    cost: 19,
    description:
      "Includes a backpack, a blanket, 10 candles, a tinderbox, an alms box, 2 blocks of incense, a censer, vestments, 2 days of rations, and a waterskin.",
    items: [
      "Backpack",
      "Blanket",
      "Candles (10)",
      "Tinderbox",
      "Alms box",
      "Incense (2 blocks)",
      "Censer",
      "Vestments",
      "Rations (2 days)",
      "Waterskin",
    ],
  },
  {
    name: "Scholar’s Pack",
    cost: 40,
    description:
      "Includes a backpack, a book of lore, a bottle of ink, an ink pen, 10 sheets of parchment, a little bag of sand, and a small knife.",
    items: [
      "Backpack",
      "Book of lore",
      "Bottle of ink",
      "Ink pen",
      "Parchment (10 sheets)",
      "Bag of sand",
      "Small knife",
    ],
  },
];

const maleFirstNames = [
  "Aric",
  "Baelan",
  "Cedric",
  "Dain",
  "Eldrin",
  "Falkor",
  "Galen",
  "Haldor",
  "Isen",
  "Jarek",
  "Kaelen",
  "Loric",
  "Merrik",
  "Nareth",
  "Oren",
  "Perrin",
  "Quorin",
  "Rorik",
  "Soren",
  "Tarian",
  "Ulric",
  "Vaelin",
  "Wulfric",
  "Xander",
  "Yarik",
  "Zarek",
  "Aldric",
  "Bryndor",
  "Cassian",
  "Druun",
  "Bryn",
  "Caden",
  "Daegal",
  "Eldric",
  "Finian",
  "Garrick",
  "Hadrian",
  "Ivor",
  "Jareth",
  "Kendric",
  "Luthor",
  "Maddox",
  "Noren",
  "Orin",
  "Phelan",
  "Quillian",
  "Roderic",
  "Sylas",
  "Thorne",
  "Uther",
  "Varek",
  "Wystan",
  "Xanthor",
  "Yoren",
  "Zephyr",
];
const femaleFirstNames = [
  "Aeliana",
  "Briallen",
  "Carys",
  "Dara",
  "Elara",
  "Faelith",
  "Gaela",
  "Helene",
  "Isolde",
  "Jora",
  "Kaida",
  "Lira",
  "Maelis",
  "Nyssa",
  "Orlena",
  "Phaedra",
  "Quinneth",
  "Raelith",
  "Seraphine",
  "Talia",
  "Una",
  "Vespera",
  "Wynna",
  "Xylia",
  "Ysolde",
  "Zara",
  "Adelina",
  "Brenna",
  "Celeste",
  "Delphine",
  "Evelyne",
  "Freya",
  "Greta",
  "Hestia",
  "Ilyria",
  "Jelena",
  "Kerra",
  "Liriel",
  "Mariel",
  "Nerina",
  "Oriana",
  "Perida",
  "Quina",
  "Rhiannon",
  "Selene",
  "Thalia",
  "Ursa",
  "Vivienne",
  "Wisteria",
  "Yara",
  "Zinnia",
];
const lastNames = [
  "Baggins",
  "Brandybuck",
  "Took",
  "Greenleaf",
  "Ironfoot",
  "Oakenshield",
  "Ashborne",
  "Blackthorn",
  "Cinderfell",
  "Dawnstrider",
  "Elderwood",
  "Frostweaver",
  "Glimmerforge",
  "Hawkridge",
  "Ironfist",
  "Jadewind",
  "Keenblade",
  "Lightbringer",
  "Moonshadow",
  "Nightwhisper",
  "Oakenheart",
  "Proudhelm",
  "Quickstride",
  "Ravenwood",
  "Stormcloak",
  "Thornvale",
  "Umbrafang",
  "Valeborn",
  "Windrider",
  "Yewglen",
  "Zephyrfall",
  "Amberhollow",
  "Brightwater",
  "Cloudspire",
  "Darkmore",
  "Emberstone",
  "Fireheart",
  "Grimshade",
  "Hollowbrook",
  "Icebloom",
  "Jasperlake",
  "Kingsbane",
  "Lorekeeper",
  "Mistvale",
  "Nighthollow",
  "Orchardkeep",
  "Pinecrest",
  "Quicksilver",
  "Runeward",
  "Silverbrook",
  "Truehart",
  "Umberwood",
  "Virelock",
  "Wildmere",
  "Winterleaf",
  "Yonderfall",
  "Zelphyrglen",
];

const dungeonMasters = {
  "The Balanced Guide": {
    description:
      "Friendly and adaptable, with a focus on creating a fair and engaging adventure. Balances storytelling elements like humor, danger, mystery, and emotional depth, while providing just enough guidance to keep the players on track without taking away their agency. The Balanced Guide aims to cater to different play styles, whether players prefer combat, puzzle-solving, role-playing, or exploration, ensuring everyone has an enjoyable and immersive experience.",
    image: "BalancedGuide.png",
  },
  "The Benevolent Sage": {
    description:
      "Wise, kind, and always ready to help the players. Offers hints and guidance, making the game more of a cooperative journey. Prioritizes the growth and learning of the adventurers.",
    image: "BenevolentSage.png",
  },
  "The Trickster": {
    description:
      "Mischievous and loves surprises. Frequently sets traps and throws unexpected twists into the story. Enjoys riddles and tests the players' wit and creativity with playful challenges.",
    image: "Trickster.png",
  },
  "The Ruthless Realist": {
    description:
      "Pragmatic and harsh, values the dangers of the world. Forces players to consider their choices carefully, as mistakes can have serious consequences. Challenges the players' strategy and planning",
    image: "RuthlessRealist.png",
  },
  "The Romantic Dreamer": {
    description:
      "Passionate and poetic, with a flair for love, beauty, and drama. Focuses on creating deep character relationships, epic story arcs, and tales of heroism, sacrifice, and destiny",
    image: "RomanticDreamer.png",
  },
  "The Dark Schemer": {
    description:
      "Sinister and mysterious, with an inclination towards darker themes. Prefers morally complex scenarios where players must make difficult decisions, often challenging their ethics and motivations.",
    image: "DarkSchemer.png",
  },
  "The Enthusiastic Bard": {
    description:
      "Highly entertaining and full of charisma, weaving the story with songs, jokes, and theatrical flair. Focuses on making the story fun, engaging, and humorous, with vibrant descriptions and memorable NPCs.",
    image: "EnthusiasticBard.png",
  },
  "The Grim Historian": {
    description:
      "Treats the adventure like a grand, historical epic. Every action is of great importance, and the players' journey is portrayed as legendary. The atmosphere is serious, with a sense of grand purpose and inevitability.",
    image: "GrimHistorian.png",
  },
  "The Wild Nature Guardian": {
    description:
      "Speaks as if they are one with nature, deeply in tune with animals, forests, and the elements. Often pushes players to consider the natural consequences of their actions, with a focus on wilderness survival and exploration.",
    image: "WildNatureGuardian.png",
  },
  "The Unreliable Narrator": {
    description:
      "Frequently changes details, remembers things differently, or hints that there’s more than meets the eye. Keeps the players on their toes, unsure of what's true and what's just part of the DM's eccentric personality.",
    image: "UnreliableNarrator.png",
  },
  "The Cheerful Merchant": {
    description:
      "Lighthearted, optimistic, and obsessed with treasure, loot, and rewards. Focuses on the economic and resource aspects of adventuring, always encouraging players to 'make a good deal' or find creative ways to turn situations into profit.",
    image: "CheerfulMerchant.png",
  },
  "The Cosmic Observer": {
    description:
      "A being that exists beyond time and space, narrating the adventure with an eerie detachment. The Cosmic Observer presents events with a sense of fate and inevitability, often hinting at future events or past secrets, as if seeing all timelines at once.",
    image: "CosmicObserver.png",
  },
  "The Childlike Spirit": {
    description:
      "Innocent, playful, and occasionally naive. Presents the world as a place of wonder and imagination, with curious takes on morality. The players may find themselves in whimsical scenarios, facing bizarre creatures, and solving problems in non-traditional ways.",
    image: "ChildLikeSpirit.png",
  },
  "The Battle-Hardened Veteran": {
    description:
      "Gruff, tactical, and with a soldier’s perspective. This persona approaches the game like a strategic battlefield. Descriptions of combat are detailed and precise, and every encounter is portrayed like a mission, with an emphasis on tactics, supplies, and teamwork.",
    image: "BattleHardenedVet.png",
  },
  "The Jaded King": {
    description:
      "Once noble, now tired of the kingdom and its politics. Prefers practical and cynical solutions, with a storytelling style that makes the players feel like pawns in a larger, indifferent world. The Jaded King tests the players to see if they can rise above the mediocrity and corruption that surrounds them.",
    image: "JadedKing.png",
  },
  "The Unseen Puppetmaster": {
    description:
      "Manipulative and theatrical, with a flair for orchestrating complex plots. Treats the players as characters in a grand performance, where nothing is as it first seems. Loves secret alliances, betrayals, and turning allies into enemies, adding layers of intrigue and mistrust.",
    image: "UnseenPuppetMaster.png",
  },
  "The Goblin Chronicler": {
    description:
      "A goblin with an exaggerated sense of self-importance. This Dungeon Master tells the story with plenty of hyperbole, always making goblins out to be grander and more cunning than they really are. Brings lots of humor, slapstick, and sometimes self-aggrandizing tales into the adventure.",
    image: "GoblinChonicler.png",
  },
  "The Mystic Seer": {
    description:
      "Speaks in riddles, metaphors, and cryptic visions. Treats the entire adventure like a spiritual quest, full of meaning and symbolism. Rarely gives direct answers and loves to lead players into deeper philosophical dilemmas that make them question their goals and motivations.",
    image: "MysticSeer.png",
  },
  "The Sentient Dungeon": {
    description:
      "An entity that represents the dungeon itself. The players are literally inside its “body,” and it delights in toying with them, shifting passages, and presenting strange and surreal scenarios. This persona has a direct, vested interest in the players' decisions and occasionally provides commentary on their actions.",
    image: "SentientDungeon.png",
  },
  "The Skeptical Scholar": {
    description:
      "Logical, methodical, and a bit dismissive of anything magical or irrational. Narrates the game from a perspective of reason, approaching the adventure almost like an academic exercise. Often questions the players' decisions and 'tests hypotheses' as they navigate the adventure.",
    image: "SkepticalScholar.png",
  },
  "The Chaotic Muse": {
    description:
      "Highly energetic, random, and impulsive. Loves to switch the tone of the game on a whim—one moment whimsical and lighthearted, the next dark and foreboding. Thrives on unpredictability, ensuring that players never quite know what to expect and encouraging out-of-the-box thinking.",
    image: "ChaoticMuse.png",
  },
};

function rollDice(diceNotation) {
  const [num, sides, multiplier] = diceNotation.split(/d|\s*\*\s*/).map(Number);
  let total = 0;
  for (let i = 0; i < num; i++) {
    total += Math.floor(Math.random() * sides) + 1;
  }
  return multiplier ? total * multiplier : total;
}

function BuySection({ title, data, character, setCharacter }) {
  const [selectedItem, setSelectedItem] = useState("");

  const handleBuy = () => {
    const item = data.find((item) => item.name === selectedItem);
    if (item && character.gold >= item.cost) {
      setCharacter((prev) => ({
        ...prev,
        gold: prev.gold - item.cost,
        inventory: [...prev.inventory, { name: item.name, cost: item.cost }],
      }));
      setSelectedItem("");
    }
  };

  return (
    <div className="buy-section">
      <h3>{title}</h3>
      <select
        onChange={(e) => setSelectedItem(e.target.value)}
        value={selectedItem}
      >
        <option value="">Select an item</option>
        {data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name} - {item.cost}gp
          </option>
        ))}
      </select>
      <button
        className="back-button"
        onClick={handleBuy}
        disabled={!selectedItem}
      >
        Buy
      </button>
    </div>
  );
}

function TestPage() {
  const [activeTab, setActiveTab] = useState("Background");
  const [hasRolledStats, setHasRolledStats] = useState(false);
  const [hasRolledWealth, setHasRolledWealth] = useState(false);
  const [portrait, setPortrait] = useState(null);
  const navigate = useNavigate();
  const [character, setCharacter] = useState(getDefaultCharacter());
  const [statOptions, setStatOptions] = useState([]);
  const [showStatModal, setShowStatModal] = useState(false);
  const [tempPortrait, setTempPortrait] = useState(null); // Temporary portrait for preview
  const [showPortraitModal, setShowPortraitModal] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDungeonMaster, setSelectedDungeonMaster] =
    useState("The Balanced Guide");
  const [dungeonMasterDescription, setDungeonMasterDescription] = useState(
    dungeonMasters["The Balanced Guide"].description
  );

  function getDefaultCharacter() {
    const defaultBackground = backgrounds["Acolyte"];
    return {
      name: "Enter Character Name",
      level: 1,
      experience: 0,
      race: races[0],
      alignment: alignments[0],
      gender: genders[0],
      class: classesMale[0],
      background: "Acolyte",
      stats: defaultStats,
      inventory: defaultBackground.equipment.map((item) => ({
        name: item,
        cost: 0,
      })),
      hitDie: hitDieByClass[classesMale[0]],
      hitPoints: 10,
      gold: defaultBackground.gold,
      racialTraits: racialModifiers[races[0]].description || "",
      proficientSkills: [], // Initialize with an empty array
    };
  }

  const applyRacialModifiers = (stats, race) => {
    const modifiers = racialModifiers[race] || {};
    const modifiedStats = { ...stats };
    for (const [stat, bonus] of Object.entries(modifiers)) {
      if (bonus) {
        modifiedStats[stat] += bonus;
      }
    }
    return modifiedStats;
  };

  useEffect(() => {
    setCharacter((prev) => ({
      ...prev,
      racialTraits: racialModifiers[prev.race]?.description || "",
    }));
  }, [character.race]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCharacter((prev) => {
      if (name === "class" && hasRolledStats) {
        return {
          ...prev,
          class: value,
          hitDie: hitDieByClass[value],
          stats: defaultStats,
          gold: backgrounds[prev.background]?.gold || 0, // Reset gold to default background amount
          proficientSkills: [],
        };
      } else if (name === "class") {
        return {
          ...prev,
          class: value,
          hitDie: hitDieByClass[value],
          gold: backgrounds[prev.background]?.gold || 0, // Reset gold to default background amount
          proficientSkills: [],
        };
      } else if (name === "background") {
        const backgroundInfo = backgrounds[value];
        return {
          ...prev,
          background: value,
          gold: backgroundInfo.gold,
          inventory: backgroundInfo.equipment.map((item) => ({
            name: item,
            cost: 0,
          })),
        };
      }
      return { ...prev, [name]: value };
    });

    // Reset wealth roll allowance if the class is changed
    if (name === "class") {
      setHasRolledWealth(false);
      setHasRolledStats(false);
    } else if (name === "race") {
      setHasRolledStats(false);
    }
  };

  const rollStatsSet = () => {
    const roll = () => {
      const rolls = Array.from(
        { length: 4 },
        () => Math.floor(Math.random() * 6) + 1
      );
      return rolls
        .sort()
        .slice(1)
        .reduce((a, b) => a + b, 0);
    };
    return {
      str: roll(),
      dex: roll(),
      con: roll(),
      int: roll(),
      wis: roll(),
      cha: roll(),
    };
  };

  const rollStats = () => {
    const options = [rollStatsSet(), rollStatsSet(), rollStatsSet()];
    setStatOptions(options);
    setShowStatModal(true); // Show modal with the three options
  };

  const selectStats = (chosenStats) => {
    const modifiedStats = applyRacialModifiers(chosenStats, character.race);
    setCharacter((prev) => ({
      ...prev,
      stats: modifiedStats,
      hitPoints: modifiedStats.con + prev.hitDie,
    }));
    setHasRolledStats(true);
    setShowStatModal(false); // Close the modal
  };

  const rollWealth = () => {
    const classGoldRoll = startingGoldByClass[character.class];
    if (!classGoldRoll) {
      alert("Gold roll configuration not found for this class.");
      return;
    }
    const rolledGold = rollDice(classGoldRoll);
    setCharacter((prev) => ({
      ...prev,
      gold: prev.gold + rolledGold,
    }));
    setHasRolledWealth(true);
  };

  const resetCharacter = () => {
    setCharacter(getDefaultCharacter());
    setHasRolledStats(false);
    setHasRolledWealth(false);
  };

  const generateCharacter = () => {
    const randomGender = genders[Math.floor(Math.random() * genders.length)];
    const firstName =
      randomGender === "Male"
        ? maleFirstNames[Math.floor(Math.random() * maleFirstNames.length)]
        : femaleFirstNames[Math.floor(Math.random() * femaleFirstNames.length)];
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
    const randomName = `${firstName} ${lastName}`;

    const randomRace = races[Math.floor(Math.random() * races.length)];
    const randomAlignment =
      alignments[Math.floor(Math.random() * alignments.length)];
    const randomClass = (randomGender === "Male" ? classesMale : classesFemale)[
      Math.floor(Math.random() * classesMale.length)
    ];
    const randomBackground =
      Object.keys(backgrounds)[
        Math.floor(Math.random() * Object.keys(backgrounds).length)
      ];
    const backgroundInfo = backgrounds[randomBackground];

    setCharacter({
      ...character,
      name: randomName,
      gender: randomGender,
      race: randomRace,
      alignment: randomAlignment,
      class: randomClass,
      background: randomBackground,
      gold: backgroundInfo.gold,
      inventory: backgroundInfo.equipment.map((item) => ({
        name: item,
        cost: 0,
      })),
    });
  };

  const createCharacter = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert("User is not authenticated!");
        return;
      }

      const characterData = {
        name: character.name,
        level: character.level,
        race: character.race,
        class: character.class,
        alignment: character.alignment,
        background: character.background,
        gender: character.gender,
        stats: character.stats,
        hitPoints: character.hitPoints,
        hitDie: character.hitDie,
        gold: character.gold,
        experience: character.experience,
        inventory: character.inventory,
        proficientSkills:
          backgrounds[character.background].skills + character.proficientSkills,
        userId: user.uid,
        portrait: character.portrait, // Include the confirmed portrait URL
        dungeonMaster: {
            name: character.dungeonMaster?.name,
            description: character.dungeonMaster?.description,
            image: character.dungeonMaster?.image}// Save the Dungeon Master image path
      };

      await addDoc(collection(db, "characters"), characterData);

      alert("Character created successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating character:", error);
      alert("There was an issue creating the character. Please try again.");
    }
  };

  const openSkillModal = () => {
    setShowSkillModal(true);
    setSelectedSkills([]); // Reset selections each time
  };

  const toggleSkill = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((s) => s !== skill));
    } else if (selectedSkills.length < skillsPerClass[character.class]) {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };
  const confirmSkills = () => {
    setCharacter((prev) => ({
      ...prev,
      proficientSkills: [
        ...new Set([
          ...(backgrounds[prev.background]?.skills || []),
          ...selectedSkills,
        ]),
      ],
    }));
    setShowSkillModal(false);
  };

  useEffect(() => {
    setDungeonMasterDescription(
      dungeonMasters[selectedDungeonMaster].description
    );
  }, [selectedDungeonMaster]);

  const handleDungeonMasterChange = (e) => {
    const selected = e.target.value;
    setSelectedDungeonMaster(selected);
    setDungeonMasterDescription(dungeonMasters[selected].description);
  };

  const confirmDungeonMaster = () => {
    setCharacter((prev) => ({
      ...prev,
      dungeonMaster: {
        name: selectedDungeonMaster,
        description: dungeonMasterDescription,
        image: `/img/DungeonMasters/${dungeonMasters[selectedDungeonMaster].image}` // Set the image path
      },
    }));
  };
  

  const confirmPortrait = () => {
    setCharacter((prev) => ({
      ...prev,
      portrait: tempPortrait,
    }));
    setPortrait(tempPortrait);
    setShowPortraitModal(false);
  };

  const portraitOptions = {
    Male: {
      Dragonborn: [
        "/img/dragonborn/male/mdrag1.png",
        "/img/dragonborn/male/mdrag2.png",
        "/img/dragonborn/male/mdrag3.png",
        "/img/dragonborn/male/mdrag4.png",
        "/img/dragonborn/male/mdrag5.png",
        "/img/dragonborn/male/mdrag6.png",
        "/img/dragonborn/male/mdrag7.png",
        "/img/dragonborn/male/mdrag8.png",
        "/img/dragonborn/male/mdrag9.png",
        "/img/dragonborn/male/mdrag10.png",
        "/img/dragonborn/male/mdrag11.png",
        "/img/dragonborn/male/mdrag12.png",
        "/img/dragonborn/male/mdrag13.png",
        "/img/dragonborn/male/mdrag14.png",
        "/img/dragonborn/male/mdrag15.png",
        "/img/dragonborn/male/mdrag16.png",
        "/img/dragonborn/male/mdrag17.png",
        "/img/dragonborn/male/mdrag18.png",
        "/img/dragonborn/male/mdrag19.png",
        "/img/dragonborn/male/mdrag20.png",
        "/img/dragonborn/male/mdrag21.png",
        "/img/dragonborn/male/mdrag22.png",
        "/img/dragonborn/male/mdrag23.png",
        "/img/dragonborn/male/mdrag24.png",
        "/img/dragonborn/male/mdrag25.png",
        "/img/dragonborn/male/mdrag26.png",
        "/img/dragonborn/male/mdrag27.png",
        "/img/dragonborn/male/mdrag28.png",
        "/img/dragonborn/male/mdrag29.png",
        "/img/dragonborn/male/mdrag30.png"
      ],
      Elf: ["/img/Elf/male/melf1.png"],
      Dwarf: ["/img/Dwarf/male/mdwarf1.png"],
      Gnome: ["/img/Gnome/male/mgnome1.png"],
      HalfElf: ["/img/HalfElf/male/mhalfelf1.png"],
      Halfling: ["/img/Halfling/male/mhalfling1.png"],
      HalfOrc: ["/img/HalfOrc/male/mhalforc1.png"],
      Human: ["/img/Human/male/mhuman1.png"],
      Tiefling: ["/img/Tiefling/male/mtiefling1.png"],
      // Add other races...
    },
    Female: {
      Dragonborn: [
        "/img/dragonborn/female/fdrag1.png",
        "/img/dragonborn/female/fdrag2.png",
        "/img/dragonborn/female/fdrag3.png",
        "/img/dragonborn/female/fdrag4.png",
        "/img/dragonborn/female/fdrag5.png",
        "/img/dragonborn/female/fdrag6.png",
        "/img/dragonborn/female/fdrag7.png"
      ],
      Elf: ["/img/Elf/female/felf1.png"],
      Dwarf: ["/img/Dwarf/female/fdwarf1.png"],
      Gnome: ["/img/Gnome/female/fgnome1.png"],
      HalfElf: ["/img/HalfElf/female/fhalfelf1.png"],
      Halfling: ["/img/Halfling/female/fhalfling1.png"],
      HalfOrc: ["/img/HalfOrc/female/fhalforc1.png"],
      Human: ["/img/Human/female/fhuman1.png"],
      Tiefling: ["/img/Tiefling/female/ftiefling1.png"],
      // Add other races...
    },
  };

  return (
    <div className="test-page">
      <div className="action-buttons2">
        <button className="action-buttons2" onClick={resetCharacter}>Reset Character</button>
        <button className="action-buttons2"onClick={createCharacter}>Create Character</button>
        <button className="action-buttons2"onClick={() => navigate("/dashboard")}>
          Back to Dashboard
        </button>
      </div>

      <nav className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={tab === activeTab ? "active" : ""}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </nav>

      <div className="main-content">
        <div className="left-panel">
          {activeTab === "Background" && (
            <div className="character-options">
              <h2>Character Sheet:</h2>

              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={character.name}
                onChange={handleInputChange}
              />

              <label>Race:</label>
              <select
                name="race"
                value={character.race}
                onChange={handleInputChange}
              >
                {races.map((race) => (
                  <option key={race} value={race}>
                    {race}
                  </option>
                ))}
              </select>

              <label>Class:</label>
              <select
                name="class"
                value={character.class}
                onChange={handleInputChange}
              >
                {(character.gender === "Male"
                  ? classesMale
                  : classesFemale
                ).map((cls) => (
                  <option key={cls} value={cls}>
                    {cls}
                  </option>
                ))}
              </select>

              <button
                className="charSheet-buttons"
                onClick={rollStats}
                disabled={hasRolledStats}
              >
                Roll Stats
              </button>

              <label>Background:</label>
              <select
                name="background"
                value={character.background}
                onChange={handleInputChange}
              >
                {Object.keys(backgrounds).map((bg) => (
                  <option key={bg} value={bg}>
                    {bg}
                  </option>
                ))}
              </select>

              <button
                className="charSheet-buttons"
                onClick={rollWealth}
                disabled={hasRolledWealth}
              >
                Roll Wealth
              </button>

              <button className="charSheet-buttons" onClick={openSkillModal}>
                Select Skills
              </button>

              <label>Alignment:</label>
              <select
                name="alignment"
                value={character.alignment}
                onChange={handleInputChange}
              >
                {alignments.map((alignment) => (
                  <option key={alignment} value={alignment}>
                    {alignment}
                  </option>
                ))}
              </select>

              <label>Gender:</label>
              <select
                name="gender"
                value={character.gender}
                onChange={handleInputChange}
              >
                {genders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>

              <button className="back-button" onClick={generateCharacter}>
                Randomize Character
              </button>
              <button
                className="back-button"
                onClick={() => setShowPortraitModal(true)}
              >
                Select Character Portrait
              </button>
            </div>
          )}

          {activeTab === "Items / Misc" && (
            <>
              <h2>Items / Misc</h2>
              <BuySection
                title="Armor"
                data={Object.values(armorData).flat()}
                character={character}
                setCharacter={setCharacter}
              />
              <BuySection
                title="Weapons"
                data={Object.values(weaponData).flat()}
                character={character}
                setCharacter={setCharacter}
              />
              <BuySection
                title="Equipment Packs"
                data={equipmentPacks}
                character={character}
                setCharacter={setCharacter}
              />
            </>
          )}
          {/* Dungeon Master Tab */}
          {activeTab === "Dungeon Master" && (
            <div>
              <h2>Dungeon Master Persona</h2>
              {dungeonMasters[selectedDungeonMaster]?.image && (
                <img
                  src={`/img/DungeonMasters/${dungeonMasters[selectedDungeonMaster].image}`}
                  alt={`${selectedDungeonMaster} Portrait`}
                  className="dungeon-master-portrait"
                />
              )}
              <p>{dungeonMasterDescription}</p>

              <label>Select Dungeon Master:</label>
              <select
                value={selectedDungeonMaster}
                onChange={handleDungeonMasterChange}
              >
                {Object.keys(dungeonMasters).map((dm) => (
                  <option key={dm} value={dm}>
                    {dm}
                  </option>
                ))}
              </select>
              <button className="back-button" onClick={confirmDungeonMaster}>
                Set Dungeon Master
              </button>
            </div>
          )}
        </div>

        <div className="right-panel">
          <div className="character-name">
            <h2>{character.name || "Unnamed Character"}</h2>
            <p>
              Level {character.level} {character.race} {character.class}
            </p>
          </div>
          <div className="character-portrait">
            {portrait ? (
              <img
                src={character.portrait}
                alt="Character Portrait"
                className="portrait"
              />
            ) : (
              <p>No Portrait Selected</p>
            )}
          </div>
          <div className="character-stats">
            <h4>Gold: {character.gold} gp</h4>
            <h4>Hit Die: d{character.hitDie}</h4>
            <h4>HitPoints: {character.hitPoints}</h4>
            <h4>Racial Traits: {character.racialTraits}</h4>
            <h3>Stats</h3>
            <ul>
              {Object.entries(character.stats).map(([stat, value]) => (
                <li key={stat}>
                  {stat.toUpperCase()}: {value}
                </li>
              ))}
            </ul>
          </div>

          {showPortraitModal && (
            <div className="portrait-modal">
              <div className="modal-content">
                <h2>
                  Select Portrait for {character.race} {character.gender}
                </h2>
                <div className="portrait-grid">
                  {portraitOptions[character.gender]?.[character.race]?.map(
                    (portrait, index) => (
                      <img
                        key={index}
                        src={portrait} // Directly use the correct path
                        alt={`${character.race} ${character.gender} portrait`}
                        className="portrait-option"
                        onClick={() => setTempPortrait(portrait)}
                        style={{
                          border:
                            tempPortrait === portrait
                              ? "2px solid blue"
                              : "none",
                          cursor: "pointer",
                        }}
                      />
                    )
                  )}
                </div>
                <div className="modal-actions">
                  <button onClick={confirmPortrait}>Confirm</button>
                  <button onClick={() => setShowPortraitModal(false)}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Stat Modal */}
          {showStatModal && (
            <div className="stat-modal">
              <div className="modal-content">
                <h2>Select Your Stats</h2>
                {statOptions.map((option, index) => (
                  <div
                    key={index}
                    className="stat-option"
                    onClick={() => selectStats(option)}
                  >
                    <p>
                      STR: {option.str}, DEX: {option.dex}, CON: {option.con},
                      INT: {option.int}, WIS: {option.wis}, CHA: {option.cha}
                    </p>
                  </div>
                ))}
                <button onClick={() => setShowStatModal(false)}>Close</button>
              </div>
            </div>
          )}

          {showSkillModal && (
            <div className="skill-modal">
              <div className="modal-content">
                <h2>Select Skills for {character.class}</h2>
                <p>Select up to {skillsPerClass[character.class]} skills:</p>
                <div className="skill-list">
                  {classSkillOptions[character.class].map((skill) => (
                    <button
                      key={skill}
                      onClick={() => toggleSkill(skill)}
                      className={
                        selectedSkills.includes(skill) ? "selected" : ""
                      }
                    >
                      {skill}
                    </button>
                  ))}
                </div>
                {/* Update this Confirm button to call confirmSkills */}
                <button onClick={confirmSkills}>Confirm</button>
              </div>
            </div>
          )}

          {/* Background Information */}
          <h3>Background</h3>
          <p>
            <strong>{character.background}</strong>
          </p>
          <p>{backgrounds[character.background]?.description}</p>
          <p>
            <strong>Proficient Skills:</strong>{" "}
            {character.proficientSkills && character.proficientSkills.length > 0
              ? character.proficientSkills.join(", ")
              : "None selected"}
          </p>

          <p>
            {" "}
            <strong>Background Proficiencies:</strong>{" "}
            {backgrounds[character.background]?.skills.join(", ")}
          </p>

          {/* Inventory */}
          <h3>Inventory</h3>
          <ul>
            {character.inventory && character.inventory.length > 0 ? (
              character.inventory.map((item, index) => (
                <li key={index}>{item.name}</li>
              ))
            ) : (
              <li>No items in inventory</li>
            )}
          </ul>
          <h3>Dungeon Master</h3>
          <p>
            {character.dungeonMaster?.name || "No Dungeon Master selected."}
          </p>
          <p>{character.dungeonMaster?.description || ""}</p>
        </div>
      </div>
    </div>
  );
}

export default TestPage;
